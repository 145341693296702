import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import avatar from '../../../../../../../assets/default-img.png';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { PulseSpinner } from '../../../../../../common/components/spinner/spinner';
import { successToast, errorToast } from 'src/pages/common/components/snackBar/toast';
import { DatePickerCustomize } from 'src/pages/common/components/datePicker/DatePickerCustomize';
import { ToastContainer } from 'react-toastify';
import {
  UPDATE_USER_PROFILE, patchRequest, UPDATE_ADMIN_USER_PROFILE, ADD_SIGNATURE_URL,
  postRequest, CHECK_PRACTICE_GROUP_AVAILABILITY, getRequest, CHECK_EMAIL_USERNAME_AVAILABILITY_URL,
} from '../../../../../../../crud/crud';
import { setUserName, updateUserSignature } from '../../../../../../../redux/action/setUserHandler';
import css from './editUser.module.css';
import { momentWrapper } from 'src/momentWrapper';
import { emailRegex } from 'src/utils';
import { matchPhoneRegex, phoneRegex } from 'src/regexes';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

export const EditUser = ({ userData, show, setShow, setUserData, _listOfSignatures, sethasEdited }) => {
  const { user } = useSelector((state) => state?.user)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const [firstName, setFirstName] = useState(``);
  const [lastName, setLastName] = useState(``);
  const [dateOfBirth, setDateOfBirth] = useState();
  const [dateError, setDateError] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState(``);
  const [email, setEmail] = useState(``);
  const [userId, setUserId] = useState(``);
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);
  const [isPhoneAvailable, setIsPhoneAvailable] = useState(true);
  const [profilePic, setProfilePic] = useState(``);
  const [signatureError, setSignatureError] = useState(false);
  const [signatureList, setSignatureList] = useState([]);
  const [TFA, setTFA] = useState(false);
  const [validPhoneFlag, setValidPhoneFlag] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const profilePicRef = useRef(null)
  const phoneRef = useRef(null);
  // CONVERTING IMAGE TO BASE-64

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function () { };
  }

  // UPLOADING SIGNATURE
  const uploadSignature = (picture) => {
    if (picture.target.files.length === 0) {
      return;
    }

    const validImageTypes = [`image/jpeg`, `image/png`];
    if (!validImageTypes.includes(picture.target.files[0].type) || picture.target.files[0].size > MAX_FILE_SIZE) {
      // invalid file type code goes here.
      setSignatureError(true);
      return;
    } else {
      setSignatureError(false);
    }
    getBase64(picture.target.files[0], (result) => {
      if (result) {
        setLoading(true);
        postRequest(ADD_SIGNATURE_URL, { signature: result })
          .then((response) => {
            dispatch(updateUserSignature(response?.data.signature))
            setSignatureList([response?.data?.signature]);
            setLoading(false);
            successToast(response?.data?.message);
          })
          .catch((err) => {
            setLoading(false);

          });
      }
    });
  };

  // VALIDATE FIRST NAME
  const validateFirstName = (val) => {
    if (val.match(`^[a-zA-Z ]*$`) != null) {
      setFirstName(val);
    }
  };

  // VALIDATE LAST NAME
  const validateLastName = (val) => {
    if (val.match(`^[a-zA-Z ]*$`) != null) {
      setLastName(val);
    }
  };

  const thirdStatementAppend = (x) => {
    if (x[3]) {
      return `-` + x[3];
    }
    return ``;
  };

  // PHONE MASK
  const phoneMask = (val) => {
    let temp = ``;
    let x = val.replace(/\D/g, ``).match(matchPhoneRegex);
    temp = !x[2] ? x[1] : `(` + x[1] + `) ` + x[2] + thirdStatementAppend(x);
    setPhoneNumber(temp);
  };

  // CHECK VALIDATIONS, EMAIL & PHONE NUMBER
  const isAvailable = (val, key) => {
    if (key == `groupName`) {
      if (val?.length > 0) {
        setLoading(true);
        getRequest(CHECK_PRACTICE_GROUP_AVAILABILITY + { name: val })
          .then(() => {
            setLoading(false);
            successToast(`Available`);
          })
          .catch((e) => {
            errorToast(`Some Error Occurred!`);
            setLoading(false);

          });
      } else {
        return null;
      }
    } else if (!key == `groupName`) {
      if (key === `email`) {
        if (userData.email === val) {
          setIsEmailAvailable(true);
          return;
        }

        if (!emailRegex.test(val)) {
          errorToast(`Invalid email address`);
          return;
        }
      }
      if (key === `phoneNumber`) {
        if (userData.phoneNumber === val) {
          setIsPhoneAvailable(true);
          return;
        }
      }
      setLoading(true);
      postRequest(CHECK_EMAIL_USERNAME_AVAILABILITY_URL, { userName: val })
        .then(() => {
          //
          setLoading(false);
          successToast(`Available`);
          if (key === `email`) {
            setIsEmailAvailable(true);
          }
          if (key === `phoneNumber`) {
            setIsPhoneAvailable(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          errorToast(err?.response?.data?.message ? err?.response?.data?.message : err?.response?.data?.error);
          if (key === `email`) {
            setIsEmailAvailable(false);
          }
          if (key === `phoneNumber`) {
            setIsPhoneAvailable(false);
          }
        });
    }
  };


  useEffect(() => {
    setFirstName(userData.firstName);
    setLastName(userData.lastName);
    setTFA(userData?.tfa ? userData?.tfa : false);
    if (userData?.dateOfBirth) {
      setDateOfBirth(momentWrapper(userData?.dateOfBirth).toDate());
    }
    setPhoneNumber(userData.phoneNumber);
    setEmail(userData.email);
    setUserId(userData?._id);
    setProfilePic(userData.profilePhotoPath);
    setSignatureList(_listOfSignatures);
  }, [userData]);

  // FORM SUBMIT HANDLER
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isPhoneAvailable || !isEmailAvailable) {
      return;
    }
    const validImageTypes = [`image/jpeg`, `image/png`];
    const formData = new FormData(e.target);
    formData.append(`tfa`, TFA);
    for (let [key, value] of formData.entries()) {
      if (key === `firstName`) {
        if (!value) {
          errorToast(`First name is required`);
          return;
        }
      }
      if (key === `lastName`) {
        if (!value) {
          errorToast(`Last name is required`);
          return;
        }
      }
      if (key === `email`) {
        if (!emailRegex.test(value)) {
          errorToast(`Invalid email address`);
          return;
        }
      }
      if (key === `profilePhoto`) {
        if (value.size) {
          if (!validImageTypes.includes(value.type) || value.size > MAX_FILE_SIZE) {
            errorToast(`Only JPEG, PNG are allowed & Max allowed file size is 5 MB`);
            return;
          }
        }
      }

      if (key === `day`) {
        formData.delete(`day`);
      }
      if (key === `month`) {
        formData.delete(`month`);
      }
      if (key === `year`) {
        formData.delete(`year`);
      }
    }

    formData.delete(`day`);
    formData.delete(`month`);
    formData.delete(`year`);
    const DOB = formData.get(`dateOfBirth`);
    formData.set(`dateOfBirth`, momentWrapper(DOB, `MM/DD/YYYY`).format(`YYYY-MM-DD`));

    if (user?.isSystemAdministrator) {
      setLoading(true);
      patchRequest(UPDATE_ADMIN_USER_PROFILE + user._id, formData)
        .then((response) => {
          setUserData(response?.data?.user);
          dispatch(setUserName({
            firstName: response?.data?.user?.firstName,
            lastName: response?.data?.user?.lastName,
          }))
          successToast(`User profile edit successfully`);
          setShow(false);
          setLoading(false);
          sethasEdited(true);
        })
        .catch((err) => {
          setLoading(false);

        });
    } else {
      setLoading(true);
      let route = `${UPDATE_USER_PROFILE}${user._id}/${user?._practiceId ? `${user?._practiceId}` : null} `
      patchRequest(route, formData)
        .then((response) => {
          setUserData(response?.data?.user);
          dispatch(setUserName({
            firstName: response?.data?.user?.firstName,
            lastName: response?.data?.user?.lastName,
          }))
          successToast(`User profile edit successfully`);
          setShow(false);
          setLoading(false);
          sethasEdited(true);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    try {
      const base64 = await convertBase64(file);
      if (base64) {
        setProfilePic(base64);
        profilePicRef.current.src = base64
      }
    } catch (error) {
      return;
    }
  };

  const convertBase64 = (file) => {
    if (file === undefined) {
      return;
    }
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const checkValidPhone = () => {
    if (!phoneRegex.test(phoneRef.current.value)) {
      setValidPhoneFlag(true);
    } else {
      setValidPhoneFlag(false);
    }
  };
  return (
    <div>
      <CustomizedDialogs noTitle={true} title="Edit Profile" open={show} setOpen={() => handleClose()} size="md" fullWidth={true}>
        <ToastContainer />
        {otpLoading && <PulseSpinner />}
        <form onSubmit={handleSubmit}>
          <div>
            <div className="row">
              <div className="col-6 d-flex flex-wrap">
              </div>

              <div className="col-6 d-flex justify-content-end pr-4">
                <img
                  ref={profilePicRef}
                  src={profilePic ? `${profilePic}` : avatar}
                  alt="Profile"
                  width="50px"
                  height="50px"
                  style={{
                    border: `1px solid darkgray`,
                    borderRadius: `26px`,
                  }}
                />
              </div>
            </div>
            <div className="d-flex w-100">
              <div className="mt-2" style={{ width: `100%` }}>
                <div className="w-100">
                  <div className="d-flex flex-wrap">
                    <div style={{ marginLeft: 0, width: `32%` }}>
                      <div className="form-group">
                        <label htmlFor="firstName">First Name</label>
                        <input
                          onChange={(e) => validateFirstName(e.target.value)}
                          value={firstName}
                          name="firstName"
                          type="text"
                          id="firstName"
                          style={{
                            width: `100%`,
                            padding: `2%`,
                            textTransform: `capitalize`,
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: `1%`, width: `32%` }}>
                      <div className="form-group">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                          onChange={(e) => validateLastName(e.target.value)}
                          value={lastName}
                          type="text"
                          name="lastName"
                          id="lastName"
                          style={{
                            width: `100%`,
                            padding: `2%`,
                            textTransform: `capitalize`,
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: `1%`, width: `32%` }}>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          type="text"
                          onBlur={() => isAvailable(email, `email`)}
                          onChange={(e) => setEmail(e.target.value)}
                          name="email"
                          id="email"
                          value={email}
                          style={{ width: `100%`, padding: `2%` }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100 mt-2">
                  <div className="d-flex flex-wrap">
                    <div style={{ marginLeft: 0, width: `32%` }}>
                      <div className="form-group">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input
                          onBlur={() => {
                            isAvailable(phoneNumber, `phoneNumber`)
                          }}
                          onChange={(e) => {
                            phoneMask(e.target.value)
                            checkValidPhone()
                          }}
                          ref={phoneRef}
                          name="phoneNumber"
                          value={phoneNumber}
                          type="text"
                          id="phoneNumber"
                          style={{ width: `100%`, padding: `2%` }}
                        />
                        {validPhoneFlag && (
                          <p style={{ color: `red` }}>Phone Number Must Be Valid</p>
                        )}
                      </div>
                    </div>
                    <div style={{ marginLeft: `1%`, width: `32%` }}>
                      <div className="form-group">
                        <label htmlFor="dateOfBirth">Date of Birth</label>
                        <DatePickerCustomize
                          name="dateOfBirth"
                          setDateInputError={setDateError}
                          dateInputError={dateError}
                          dateInputValue={dateOfBirth || null}
                          setDateInputValue={setDateOfBirth}
                          pickerProps={{ size: `small` }}
                        />
                        {dateError && (
                          <p style={{ color: `red` }}>Please Input Valid Date</p>
                        )}
                      </div>
                    </div>

                    <div style={{ marginLeft: `1%`, width: `32%` }}>
                      <div className="form-group">
                        <label htmlFor="User_Id">User Id</label>
                        <input
                          type="text"
                          name="User_Id"
                          id="User_Id"
                          disabled
                          value={userId}
                          style={{ width: `100%`, padding: `2%` }}
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: `1%`, width: `32%` }}>
                      <div className="custom-file mt-4">
                        <input
                          type="file"
                          name="profilePhoto"
                          className="custom-file-input"
                          id="profilePhoto"
                          accept=".jpg, .jpeg, .png"
                          onChange={(e) => handleImageUpload(e)}
                        />
                        <label className="custom-file-label" htmlFor="profilePhoto">
                          Profile Picture
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!user?.isGroupAdmin && <hr style={{ marginTop: 20 }} />}
          <div style={{ display: `flex`, justifyContent: `flex-end` }}>
            <button
              className="btn btn-outline-info"
              disabled={validPhoneFlag || dateError || loading}
              type="submit"
              variant="outline-info"
              id={css.addNewUserModalButton}
            >
              Save
            </button>
          </div>
        </form>
        {loading && <PulseSpinner />}
      </CustomizedDialogs>
    </div>
  );
}




