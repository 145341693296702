export const npiRegex = /^[a-z]{0,10}$/;
export const phoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
export const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const matchNpiRegex = /(\d{0,10})/;
export const matchPhoneRegex = /(\d{0,3})(\d{0,3})(\d{0,4})/;
export const faxMatchRegex = /(\d{0,3})(\d{0,3})(\d{0,4})/;
export const patientRouteMatch = /[\[\]]/g;
export const filteredAllPatientsListMatch = /[\[\]]/g;
export const removeWhiteSpace = /\s/g 
